/* src/Menu.css */

.menu-container {
  padding: 20px;
}

.menu-content {
  max-width: 600px;
  margin: 0 auto;
}

.error-message {
  background-color: #ffebee;
  color: #c62828;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.thought-input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  min-height: 100px;
  font-family: inherit;
}

.submit-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  opacity: 1;
}

.submit-button:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.thoughts-group {
  margin-top: 30px;
}

.thought-item {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.thought-text {
  margin: 0 0 10px 0;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

.thought-timestamp {
  color: #555;
  font-size: 12px;
}
