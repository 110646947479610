/* Prompter.css */

.prompter-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  color: #f0f0f0; /* Light text on dark background */
  font-family: sans-serif; /* Add a default font */
}

.prompter-container h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #f0f0f0;
  font-size: 2em; /* Larger heading */
}

.description {
  text-align: center;
  margin-bottom: 30px;
  color: #ccc;
  font-style: italic; /* Italicize description */
}

/* Styles for Query Input */
.query-input {
  margin-bottom: 30px;
}

.query-input label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.query-input input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #555;
  background-color: #333;
  color: #f0f0f0;
  font-size: 1rem;
}

/* Styles for Properties Container (Grid) */
.properties-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive grid */
  gap: 25px; /* Space between grid items */
  margin-bottom: 30px;
}

/* Styles for Slider Group */
.slider-group {
  background-color: #3a3a3a; /* Slightly lighter background for grouping */
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.slider-group label {
  display: block; /* Label on its own line */
  margin-bottom: 10px; /* Space between label and slider */
  font-weight: bold;
  color: #ddd; /* Lighter label color */
}

.slider-group .slider-value {
  font-weight: normal; /* Normal weight for the value */
  color: #a0e9ff; /* Highlight color for the selected value */
  margin-left: 8px; /* Space between label text and value */
  display: inline-block; /* Keep it inline but allow margin */
}

/* Styles for the Slider Input */
.slider {
  width: 100%; /* Slider takes full width of its container */
  cursor: pointer;
  height: 8px; /* Adjust height */
  background: #555; /* Track background */
  border-radius: 5px;
  outline: none;
  appearance: none; /* Override default look */
  -webkit-appearance: none; /* Override default look for WebKit */
}

/* Customizing Slider Thumb (the draggable part) */
.slider::-webkit-slider-thumb {
  appearance: none;
  -webkit-appearance: none;
  width: 28px; /* Increased thumb size */
  height: 28px; /* Increased thumb size */
  background: #a0e9ff; /* Thumb color */
  border-radius: 50%; /* Make it round */
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 28px; /* Increased thumb size */
  height: 28px; /* Increased thumb size */
  background: #a0e9ff;
  border-radius: 50%;
  cursor: pointer;
  border: none; /* Remove default border in Firefox */
}

/* Styles for Generate Button */
.generate-button {
  display: block; /* Make it a block element */
  width: fit-content; /* Adjust width to content */
  margin: 30px auto; /* Center the button */
  padding: 12px 25px;
  font-size: 1.1rem;
  background-color: #007bff; /* Primary button color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.generate-button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

/* Styles for Generated Prompt Area */
.generated-prompt {
  margin-top: 30px;
  padding: 20px;
  background-color: #2a2a2a; /* Darker background for prompt area */
  border-radius: 5px;
}

.generated-prompt h2 {
  margin-top: 0;
  margin-bottom: 15px;
  color: #eee;
}

.prompt-box {
  background-color: #333; /* Background for the text itself */
  padding: 15px;
  border-radius: 4px;
  border: 1px solid #444;
  color: #f0f0f0;
  font-family: monospace; /* Monospace font for prompt */
  white-space: pre-wrap; /* Preserve whitespace and wrap */
  word-wrap: break-word;
  min-height: 100px; /* Minimum height */
  margin-bottom: 15px;
}

.copy-button {
  padding: 8px 15px;
  font-size: 0.9rem;
  background-color: #555;
  color: #f0f0f0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.copy-button:hover {
  background-color: #666;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .properties-container {
    grid-template-columns: 1fr; /* Single column on smaller screens */
  }
  .generate-button {
    width: 90%; /* Adjust width */
  }
}
