.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 0;
    /* background-color: #f8f9fa; */
    color: #212529;
    text-align: center;
    padding: 10px 0;
}
